import { useMemo } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile, isTablet, windowHeight } = useScreenSizes();

  return useMemo(() => StyleSheet.create({
    containerStyle: {
      ...Platform.select({
        native: {
          flex: 1
        }
      }),
      flexDirection: 'column',
      height: windowHeight,
      backgroundColor: theme.colors.basics.white
    },
    scrollContainerStyle: {
      flex: 1
    },
    scrollContentContainerStyle: {
      minHeight: '100%'
    },
    frameContainer: {
      flexDirection: (isMobile || isTablet) ? 'column' : 'row',
      alignSelf: 'center',
      minHeight: '100%',
      width: '100%',
      maxWidth: theme.metrics.breakpoints.desktop
    },
    cardContentStyle: {
      flex: 1,
      minHeight: '100%',
      flexDirection: 'column',
      backgroundColor: theme.colors.basics.white
    },
    stickyButtonsViewStyle: {
      paddingVertical: (isMobile || isTablet) ? theme.metrics.spacing.l : undefined,
      paddingHorizontal: (isMobile || isTablet) ? theme.metrics.spacing.s : undefined,
    },
    desktopContainer: {
      flex: 1,
      flexDirection: 'row',
      position: "relative"
    },
    contentContainer: {
      flex: 3,
    },
    modalProperties: {
      justifyContent: 'center',
      paddingLeft: 30,
    }
  }), [windowHeight, theme, isMobile, isTablet]);
};
