import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  SimpleButton,
  useScreenSizes,
  useTranslation
} from '@components/index';
import { InputMode } from '@modules/common/constants';

import { useStyles } from './useStyles';
import { ProfileEditAddressHeader } from '../components';
import { FormField, FormSelect, FormTitle } from './components';
import { useData } from './useData';
import { FormValues } from '../types';

export const ProfileEditAddressFormPage: React.FC = () => {
  const { formatMessage } = useTranslation();
  const { isDesktop } = useScreenSizes();
  const styles = useStyles();

  const { stepper, countries, inputMode, isAddressUpdateLoading } = useData();

  const { handleChange, errors, touched, handleBlur, values, setFieldValue, handleSubmit } =
    useFormikContext<FormValues>();

  const mandatoryErrorMessage = formatMessage({ id: 'PostalAdressPageMissingMandatoryInputs' });

  const renderBottomAction = useCallback(
    () => (
      <SimpleButton
        containerStyle={styles.continueButtonContainer}
        design="solid"
        loading={isAddressUpdateLoading}
        onPress={() => handleSubmit()}
        size="large"
        testId={'PostalAddressFormSubmitButton'}
        title={formatMessage({ id: 'PostalAdressPageContinue' })}
      />
    ),
    [formatMessage, handleSubmit, isAddressUpdateLoading, styles]
  );

  return (
    <DashboardPageWrapperConnected renderStickyMobileBottom={renderBottomAction}>
      <KeyboardAvoidingView
        behavior="position"
        keyboardVerticalOffset={Platform.select({ ios: -35, android: -25 })}>
        <ColumnsContainer
          hasRenderRightDesktopColumn={false}
          leftColumnStyle={styles.containerStyle}
          renderHeader={() => <ProfileEditAddressHeader stepper={stepper} />}>
          <FormTitle inputMode={inputMode || InputMode.DATA} />
          <FormField
            handleBlur={handleBlur}
            handleChange={handleChange}
            placeholder={formatMessage({ id: 'PostalAdressPageAddressee' })}
            propertyName={'additionalAddress_1'}
            setFieldValue={setFieldValue}
            testId={'PostalAddressFormAdditionalReceiverField'}
            values={values}
          />
          <FormField
            errorMessage={mandatoryErrorMessage}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasError={!!errors.street && !!touched.street}
            placeholder={formatMessage({ id: 'PostalAdressPageStreetNumberAndName' })}
            propertyName={'street'}
            setFieldValue={setFieldValue}
            testId={'PostalAddressFormStreetField'}
            values={values}
          />
          <FormField
            handleBlur={handleBlur}
            handleChange={handleChange}
            placeholder={formatMessage({ id: 'PostalAdressPageAdditionalAdress' })}
            propertyName={'additionalAddress_3'}
            setFieldValue={setFieldValue}
            testId={'PostalAddressFormAdditionalAddressField'}
            values={values}
          />
          <FormField<FormValues>
            errorMessage={mandatoryErrorMessage}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasError={!!errors.zipcode && !!touched.zipcode}
            placeholder={formatMessage({ id: 'PostalAdressPagePostalCode' })}
            propertyName={'zipcode'}
            setFieldValue={setFieldValue}
            testId={'PostalAddressFormZipCodeField'}
            values={values}
          />
          <FormField
            errorMessage={mandatoryErrorMessage}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasError={!!errors.city && !!touched.city}
            placeholder={formatMessage({ id: 'PostalAdressPageCity' })}
            propertyName={'city'}
            setFieldValue={setFieldValue}
            testId={'PostalAddressFormCityField'}
            values={values}
          />
          <FormSelect
            errorMessage={mandatoryErrorMessage}
            hasError={!!errors.country && !!touched.country}
            items={countries}
            onSelectItem={item => setFieldValue('country', item)}
            selectedItem={values.country}
            testId={'PostalAddressFormCountryField'}
          />
          {isDesktop && renderBottomAction()}
        </ColumnsContainer>
      </KeyboardAvoidingView>
    </DashboardPageWrapperConnected>
  );
};
