import { InstallmentPaymentCBHtmlPageContext, InstallmentPaymentCBHtmlPageStyle } from './interfaces';

export const getStyles = (
  context?: InstallmentPaymentCBHtmlPageContext,
  style?: InstallmentPaymentCBHtmlPageStyle,
): InstallmentPaymentCBHtmlPageStyle => ({
  containerStyle: [
    (context?.isMobile || context?.isTablet) && {
      height: context?.windowHeight
    },
    style?.containerStyle
  ],
  contentStyle: [{
      height: '100%'
    },
    style?.contentStyle
  ],
  headerContainerStyle: [{
      borderBottomWidth: 1,
      borderBottomColor: context?.theme.colors.basics.grey.c200
    },
    style?.headerContainerStyle
  ],
  cardContentStyle: [{
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.cardContentStyle
  ],
  installmentsStepperContainerStyle: [{
    alignItems: 'center',
    justifyContent: 'center'
  },
  style?.installmentsStepperContainerStyle
  ],
  stepperStyle: [
    {
      paddingBottom: context?.theme.metrics.spacing.s,
      marginTop: context && -context?.theme.metrics.spacing.s
    },
    style?.stepperStyle
  ],
  HtmlFrameStyle: [
    {
      flex: 1,
      width: '100%'
    },
    style?.HtmlFrameStyle
  ]
});
